import React from 'react';
import './Topcontainer.css';
import banner from '../assets/cpm-banner-img.webp';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Topcontainer = () => {
    return (
        <section className="topcontainer-main">
            <motion.div 
                className="topcontainer-container"
                initial={{ opacity: 0 }} 
                whileInView={{ opacity: 1 }} 
                transition={{ duration: 0.8 }}
            >
                <motion.div 
                    className="topcontainer-content"
                    initial={{ opacity: 0, x: -100 }} 
                    whileInView={{ opacity: 1, x: 0 }} 
                    transition={{ duration: 0.8 }}
                >
                    <motion.div 
                        className="content-left"
                        initial={{ opacity: 0, x: -200 }} 
                        whileInView={{ opacity: 1, x: 0 }} 
                        transition={{ duration: 0.8 }}
                    >
                        <div className="content-top"></div>
                        <motion.div 
                            className="content-middle"
                            initial={{ opacity: 0, y: 50 }} 
                            whileInView={{ opacity: 1, y: 0 }} 
                            transition={{ duration: 0.8 }}
                        >
                            <h1>Next-generation digital services</h1>
                            <p>G For Growth is your go-to resource for actionable strategies and insights designed to accelerate business success. Whether you’re a startup founder or a seasoned entrepreneur, G For Growth provides expert advice on digital marketing, business development, and growth hacking. Our mission is to empower businesses to thrive in today’s competitive landscape through proven techniques and innovative solutions.</p>
                        </motion.div>
                        <motion.div 
                            className="content-bottom"
                            initial={{ opacity: 0, y: 100 }} 
                            whileInView={{ opacity: 1, y: 0 }} 
                            transition={{ duration: 0.8 }}
                        >
                            <Link to='/contact'> 
                                <button>Get Started</button>
                            </Link>
                        </motion.div>
                    </motion.div>
                    <motion.div 
                        className="content-right"
                        initial={{ opacity: 0, x: 200 }} 
                        whileInView={{ opacity: 1, x: 0 }} 
                        transition={{ duration: 0.8 }}
                    >
                        <img src={banner} alt="" />
                    </motion.div>
                </motion.div>
            </motion.div>
        </section>
    );
};

export default Topcontainer;
