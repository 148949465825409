import React from 'react';
import './Mao.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const Map = () => {
  return (
    <div className="map-container">
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241316.67293400585!2d72.7163767053975!3d19.082502000719877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6306644edc1%3A0x5da4ed8f8d648c69!2sMumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1726572854391!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className="contact-info">
        <div className="contact-item">
          <div className="icon-container">
            <FaMapMarkerAlt className="icon" />
          </div>
          <h3>Our address</h3>
          <p>Mumbai</p>
        </div>
        <div className="contact-item">
          <div className="icon-container">
            <FaPhone className="icon" />
          </div>
          <h3>Our phone</h3>
          <p>Mobile : +91 8600800409</p>
          
        </div>
        <div className="contact-item">
          <div className="icon-container">
            <FaEnvelope className="icon" />
          </div>
          <h3>Our mail</h3>
          <p>connect@gforgrowth.com</p>
        </div>
      </div>
    </div>
  );
};

export default Map;
