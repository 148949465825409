import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-section">
                <h3>G For Growth Agency</h3>
                <p>Mumbai, India</p>
                <div className="social-icons">
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                    <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
                </div>
            </div>
            <div className="footer-section">
                <h4>COMPANY</h4>
                <ul>
                    <li><a href="/about">About</a></li>
                    {/*<li><a href="#team">Our team</a></li>*/}
                    <li><a href="/contact">Contact us</a></li>
                </ul>
            </div>
            <div className="footer-section">
                <h4>SOCIAL</h4>
                <ul>
                    <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                    <li><a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                    <li><a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">YouTube</a></li>
                </ul>
              
        
            </div>
            <div className='copy'>
            Copyright © CodeHub Technologies India Pvt.Ltd
            </div>
       
        </footer>
    );
};

export default Footer;
